import axios from './instance'

export const getCustomers = (query) => {
  return axios.get(`/customers?${query}`)
}

export const createCustomer = (data) => {
  return axios.post('/customer', data)
}

export const getCustomer = (id) => {
  return axios.get(`/customer/${id}`)
}

export const updateCustomer = (id, data) => {
  return axios.put(`/customer/${id}`, data)
}

export const deleteCustomerById = (id) => {
  return axios.delete(`/customer/${id}`)
}

export default {
  getCustomers,
  createCustomer,
  getCustomer,
  updateCustomer,
  deleteCustomerById
}
