<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Customers List
      <router-link slot="right" :to="'/customers/create'" class="button is-primary">
        Add New Customer
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
      >
        <section class="search-container">
          <b-field position="is-right">
            <b-input
              v-model="query"
              icon="magnify"
              placeholder="Search..."
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="clearQuery"
            />
          </b-field>
        </section>

        <b-table
            v-if="tableData.length || isTableLoading"
            :data="tableData"
            :loading="isTableLoading"

            hoverable
            paginated
            backend-pagination
            :total="totalCount"
            :per-page="limit"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            backend-sorting
            :default-sort-direction="defaultTableOrder"
            :default-sort="[defaultTableOrderBy, defaultTableOrder]"
            @sort="onSort">

            <b-table-column field="name" label="Customer Name" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="binCount" label="Count of bin/s" v-slot="props" centered>
                {{ props.row.bins.length }}
            </b-table-column>

            <b-table-column field="statusCode" label="Status" sortable v-slot="props" centered>
                {{ customerStatusesMapping[props.row.statusCode] || props.row.statusCode }}
            </b-table-column>

            <b-table-column field="address" label="Address" sortable v-slot="props">
                {{ props.row.address }}
            </b-table-column>

            <b-table-column field="villageCode" label="Village" sortable v-slot="props">
                {{ customerVillagesMapping[props.row.villageCode] || props.row.villageCode}}
            </b-table-column>

            <b-table-column field="timestamp_created" label="Date Time Created" sortable v-slot="props">
                {{ formatDate(props.row.timestamp_created) }}
            </b-table-column>

            <b-table-column v-slot="props" label="Action" centered>
              <b-field grouped position="is-centered">
                <div class="control">
                  <router-link :to="`customers/view/${props.row.id}`" class="button is-primary is-small">View</router-link>
                </div>
                <div class="control">
                  <b-button type="is-danger" @click="deleteCustomer(props.row.id)" size="is-small">Delete</b-button>
                </div>
              </b-field>
            </b-table-column>

        </b-table>

        <div class="content has-text-grey has-text-centered empty" v-else>
          <p>
            <b-icon icon="playlist-remove" size="is-large" />
          </p>
          <p>No records found. Click create to start adding records.</p>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import tableMixins from '@/mixins/table'
import customer from '@/services/customer'

import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'CustomersList',
  components: {
    TitleBar,
    HeroBar,
    CardComponent
  },
  mixins: [tableMixins],
  data () {
    return {
      defaultTableOrderBy: 'timestamp_created',
      defaultTableOrder: 'desc'
    }
  },
  methods: {
    fetchTableData (query) {
      return customer.getCustomers(query) // { count: 0, rows: [] }
    },
    deleteCustomer (id) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete customer?',
        onConfirm: async () => {
          try {
            await customer.deleteCustomerById(id)
            this.loadTableData()
            this.$buefy.snackbar.open({
              message: 'Company deleted successfully',
              queue: false
            })
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    formatDate (date) {
      return format(new Date(date), 'MM/dd/yyyy h:mm aa')
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'customerStatusesMapping',
      'customerVillagesMapping'
    ]),
    titleStack () {
      return ['Customers']
    }
  }
}
</script>
