import customer from '@/api/customer'

export const getCustomers = (query) => {
  return customer.getCustomers(query)
}

export const createCustomer = (data) => {
  return customer.createCustomer(data)
}

export const getCustomer = (id) => {
  return customer.getCustomer(id)
}

export const updateCustomer = (id, data) => {
  return customer.updateCustomer(id, data)
}

export const deleteCustomerById = (id) => {
  return customer.deleteCustomerById(id)
}

export default {
  getCustomers,
  createCustomer,
  getCustomer,
  updateCustomer,
  deleteCustomerById
}
